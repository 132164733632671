import { useState, FormEvent } from 'react';

export default function LandingPage() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // TODO: Implement waitlist signup API
    setSubmitted(true);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <header className="py-6">
          <div className="flex justify-between items-center">
            <div className="text-2xl font-bold">Constellation</div>
          </div>
        </header>

        <main className="py-16">
          <div className="text-center">
            <h1 className="text-5xl font-extrabold tracking-tight sm:text-6xl mb-6">
              Level-up your performance culture
            </h1>
            <p className="mt-6 text-xl max-w-2xl mx-auto">
              Transform your organization's strategy into measurable success. Connect meaningful metrics, 
              discover insights, and drive excellence across your business.
            </p>

            <div className="mt-12 sm:mt-16">
              {!submitted ? (
                <form onSubmit={handleSubmit} className="max-w-md mx-auto">
                  <div className="flex gap-4">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      required
                      className="flex-1 rounded-lg px-4 py-3 bg-gray-700 border border-gray-600 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <button
                      type="submit"
                      className="px-6 py-3 rounded-lg bg-blue-600 hover:bg-blue-700 font-medium transition-colors"
                    >
                      Join Waitlist
                    </button>
                  </div>
                </form>
              ) : (
                <div className="bg-blue-900/50 max-w-md mx-auto p-4 rounded-lg">
                  <p className="text-lg">
                    Thanks for your interest! We'll keep you updated on our progress.
                  </p>
                </div>
              )}
            </div>

            <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-3">
              <div className="p-6 rounded-lg bg-gray-800/50">
                <h3 className="text-lg font-semibold mb-2">Strategic Clarity</h3>
                <p>Transform complex metrics into clear, actionable insights</p>
              </div>
              <div className="p-6 rounded-lg bg-gray-800/50">
                <h3 className="text-lg font-semibold mb-2">Data-Driven Decisions</h3>
                <p>Make confident decisions backed by comprehensive analytics</p>
              </div>
              <div className="p-6 rounded-lg bg-gray-800/50">
                <h3 className="text-lg font-semibold mb-2">Team Alignment</h3>
                <p>Unite your organization around shared objectives and metrics</p>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}